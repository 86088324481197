import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
// import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import TabFilters from "./TabFilters";
import { PRODUCTS } from "data/data";

export interface PageCollectionProps {
  className?: string;
}

const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>Collection || Toofani Masti</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="text-custom-golden block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              collection
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span>
          </div>

          <hr className="border-slate-200 " />
          <main>
            {/* TABS FILTER */}
            <TabFilters />

            {/* LOOP ITEMS */}
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-2 sm:gap-x-8 sm:gap-y-10 mt-8 lg:mt-10">
              {PRODUCTS.map((item, index) => (
                <ProductCard data={item} key={index} />
              ))}
            </div>

            {/* --- skeletant-design --- */}
            <div className="skeletant-target hidden">
              <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-2 sm:gap-x-8 sm:gap-y-10 mt-8 lg:mt-10">

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

              </div>
            </div>
            {/* --- skeletant-design --- */}

            {/* PAGINATION */}
            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <Pagination />
              <ButtonPrimary loading>Load more..</ButtonPrimary>
            </div>
          </main>
        </div>

        {/* === SECTION 5 === */}
        <hr className="border-slate-200 " />

        <SectionSliderCollections />
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageCollection;
