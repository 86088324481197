import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Button from "shared/Button/Button";

import iconsuccess from '../../images/icons/icon-success.png'

export interface PageThankyouProps {
  data?: any;
}

const PageThankyou: FC<PageThankyouProps> = ({
  data = {},
}) => {
  return (
    <div>
      <Helmet>
        <title>Thank You For Order || Toofani Masti</title>
      </Helmet>

      <section>
          <div className="processing">
              
              <div className="p-main-bx">
                <h3 className="p-heading">Thank You For Your Ordering !</h3>
                <div className='pro-img-bx'>
                    <img src={iconsuccess} alt="icon success" />
                </div>
                <div className="p-order-id">#{data?.['order_number' as any]}</div>
                <div className="p-order-text">Your Order Is {((data?.['status' as any] == 'new') ? 'Pending' : ((data?.['status' as any] == 'process') ? 'In Process': data?.['status' as any]))}</div>
                <div className="p-order-text">Admin will check your payment details and proceed further</div>
                {/*<Link className="p-order-link" to="/account-my-order">Go to order</Link>*/}
                <Button className="p-order-link" onClick={() => { window.location.href = '/account-my-order'; } }>Go to order</Button>
              </div>
  
          </div>
      </section>

  
    </div>
  );
};

export default PageThankyou;
