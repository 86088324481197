import React, { useRef, useState } from 'react';

// Slick SLider Files
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// Lightgellery FIlles
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';

import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

interface ProductImageSliderProps {
  images: string[];
}

const ProductImageSlider: React.FC<ProductImageSliderProps> = ({ images }) => {
  const mainSlider = useRef<Slider>(null);
  const thumbnailSlider = useRef<Slider>(null);
  const [mainSliderIndex, setMainSliderIndex] = useState(0);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      setMainSliderIndex(index);
      thumbnailSlider.current?.slickGoTo(index);
    },
  };

  const thumbnailSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
  };

  const handleThumbnailClick = (index: number) => {
    mainSlider.current?.slickGoTo(index);
  };

  return (
    <div className="product-slider-container">
      {/* <Slider ref={mainSlider} {...sliderSettings} className="main-slider"> */}
      <div className='main-slider'>
        <LightGallery plugins={[lgZoom]}>
          {images.map((image, index) => (
            <a key={index} href={image}>
              <div className="p-1" key={index}>
                <div
                  className={`relative slider-item cursor-pointer thumbnail-item w-full h-[340px] md:h-[500px] lg:h-[500px] rounded-xl overflow-hidden bg-[white] border-slate-200 z-1 group hover:border-[#8672c7] transition-all duration-300 ${index === mainSliderIndex ? 'active' : ''}`}
                >
                  {/* Image */}
                  <img
                    className="absolute top-0 left-0 w-full h-full object-contain object-center bg-transparent border border-slate-200 hover:border-[#8672c7] transition-all duration-300 rounded-3xl"
                    src={image}
                    alt={`Product ${index + 1}`}
                  />
                  {/* Overlay with Icon */}
                  <div className="absolute inset-0 rounded-3xl bg-black bg-opacity-20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {/* Icon with background color, white stroke, and circle border-radius */}
                    <div className="bg-[#8672c7] p-2 rounded-full transition-transform duration-300 ease-in-out group-hover:scale-110">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6 transition-transform duration-300 ease-in-out group-hover:scale-110">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
                      </svg>
                    </div>
                  </div>

                </div>
              </div>
            </a>
          ))}
        </LightGallery>
      </div>
      {/* </Slider> */}

      {/* <Slider ref={thumbnailSlider} {...thumbnailSettings} className="thumbnail-slider">
        {images.map((image, index) => (
          <div className="p-1" key={index}>
            <div
              className="thumbnail-item cursor-pointer w-full h-[100px] rounded-lg overflow-hidden bg-[white]"
              onClick={() => handleThumbnailClick(index)}
            >
              <img className="w-full h-full object-contain object-center" src={image} alt={`Thumbnail ${index + 1}`} />
            </div>
          </div>
        ))}
      </Slider> */}

      {/* <LightGallery plugins={[lgZoom]}>
        {images.map((image, index) => ( 
          <a className="first:flex absolute left-[20px] top-3 w-9 h-9 flex items-center justify-center rounded-full bg-white hover:text-white hover:bg-custom-pink dark:bg-slate-900 text-neutral-700 dark:text-slate-200" key={index} href={image}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
            </svg>
          </a>
        ))}
      </LightGallery> */}
    </div>
  );
};

export default ProductImageSlider;
