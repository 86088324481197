import React, { FC } from "react";
import { Helmet } from "react-helmet-async";


const PageReturn: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Return Policy || Toofani Masti</title>
      </Helmet>

      <section className="pt-16 pb-16">
          <div className="lagle-container">
              <div className="lagle-heading">Return Policy</div>
              <div className="legal-content">
                
                  <div className="main-sub-lable">1. Eligibility for Returns</div>
                  <ul className="legal-ul">
                      <li>We accept returns for items that are unused, unworn, and in their original condition with all tags and packaging intact.</li>
                      <li>Returns must be initiated within 60 days of the delivery date.</li>
                  </ul>

                  <div className="main-sub-lable">2. How to Initiate a Return</div>
                  <ul className="legal-ul">
                      <li>Log in to your account on our website.</li>
                      <li>Go to the "Order History" section and select the order containing the item you want to return.</li>
                      <li>Follow the instructions to request a return.</li>
                  </ul>

                  <div className="main-sub-lable">3. Approval Process</div>
                  <ul className="legal-ul">
                      <li>Once your return request is submitted, it will be reviewed for eligibility.</li>
                      <li>You will receive an email notification regarding the status of your return request within 60 business days.</li>
                  </ul>

                  <div className="main-sub-lable">4. Return Shipping</div>
                  <ul className="legal-ul">
                      <li>Customers are responsible for the cost of return shipping unless the return is due to an error on our part or a defective product.</li>
                      <li>We recommend using a trackable shipping method for returns.</li>
                  </ul>


                  <div className="main-sub-lable">5. Refund Process</div>
                  <ul className="legal-ul">
                      <li>Customers are responsible for the cost of return shipping unless the return is due to an error on our part or a defective product.</li>
                      <li>We recommend using a trackable shipping method for returns.</li>
                  </ul>

                  <div className="main-sub-lable">6. Exchanges</div>
                  <ul className="legal-ul">
                      <li>We currently do not offer direct exchanges. To exchange an item, please return the original item and place a new order.</li>
                  </ul>

                  <div className="main-sub-lable">7. Damaged or Defective Items</div>
                  <ul className="legal-ul">
                      <li>If you receive a damaged or defective item, please contact our customer service team within 60 days of receiving the product.</li>
                      <li>Provide detailed information and, if possible, attach photos of the damaged or defective item.</li>
                      <li>We will provide instructions for returning the item or may arrange for a replacement.</li>
                  </ul>

                  <div className="main-sub-lable">8. Cancellations</div>
                  <ul className="legal-ul">
                      <li>Cancellations are only possible before the order is shipped. Once an order has been shipped, it cannot be canceled.</li>
                  </ul>

                  <div className="main-sub-lable">9. Contact Information</div>
                  <p>If you have any questions or concerns about our return policy, please contact our customer service team at:</p>
                  <p>Toofani Masti</p>
                  <p>Email Id: <a href="mailto:toofanimasti@gmail.com">toofanimasti@gmail.com</a>.</p>
                  <p>Phone number: <a href="tel:+918410889999">(+91) 84108 89999</a>.</p>

              </div>
          </div>
      </section>

  
    </div>
  );
};

export default PageReturn;
