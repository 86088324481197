import React, { TextareaHTMLAttributes } from "react";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className = "", children, rows = 4, ...args }, ref) => {
    return (
      <textarea
        ref={ref}
        className={`block w-full text-sm rounded-2xl focus:border-[#8672c7] focus:ring focus:ring-[#8672c747] focus:ring-opacity-50 bg-white border-neutral-200  ${className}`}
        rows={rows}
        {...args}
      >
        {children}
      </textarea>
    );
  }
);

export default Textarea;
