import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Toofani Masti</title>
        <meta name="description" content="Welcome to Toofani Masti, your premier destination for exquisite bath bombs and salts. Immerse yourself in luxury with our handcrafted products, proudly made in the heart of India and delivered to the UAE. Elevate your bathing experience with our meticulously crafted blends, designed to pamper and rejuvenate. Experience the essence of tradition and quality as we bring the artistry of Indian craftsmanship to your self-care routine. Indulge in the beauty of handmade excellence with Toofani Masti." />
        <meta property="og:site_name" content="Toofani Masti" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content='Welcome to Toofani Masti, your premier destination for exquisite bath bombs and salts. Immerse yourself in luxury with our handcrafted products, proudly made in the heart of India and delivered to the UAE. Elevate your bathing experience with our meticulously crafted blends, designed to pamper and rejuvenate. Experience the essence of tradition and quality as we bring the artistry of Indian craftsmanship to your self-care routine. Indulge in the beauty of handmade excellence with Toofani Masti.' />
        <meta property="og:title" content="Toofani Masti" />
        <meta property="og:image" itemProp="image" content="https://backend.thebodybucket.ae/backend/img/logo/logo.png" />
        <meta property="og:image:url" content="https://backend.thebodybucket.ae/backend/img/logo/logo.png" />
        <meta property="og:image:secure_url" content="https://backend.thebodybucket.ae/backend/img/logo/logo.png" />
        <meta property="og:url" content={window.location.origin} />
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base  text-slate-900 ">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
