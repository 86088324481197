import React, { FC } from "react";
import {Link} from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/ToofanimastiIspecialofferp.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import Logo from "shared/Logo/Logo";
import backgroundLineSvg from "images/Moon.svg";

export interface SectionPromo2Props {
  className?: string;
}


const SectionPromo2: FC<SectionPromo2Props> = ({ className = "lg:pt-5" }) => {
  return (
    <div className={`nc-SectionPromo2 ${className}`}>
      <Link to="/category" className="relative flex flex-col md:flex-row md:justify-end lg:flex-row lg:justify-end bg-[#f7f5ff]  rounded-2xl sm:rounded-[40px] p-4 pb-0 sm:p-5 sm:pb-0 lg:p-24">
        <div className="absolute inset-0">
          <img
            className="absolute w-full h-full object-contain dark:opacity-5"
            src={backgroundLineSvg}
            alt="backgroundLineSvg"
          />
        </div>

        <div className="w-[100%] md:w-[45%] lg:w-[45%] max-w-lg relative">
          {/* <Logo className="w-28" /> */}
          <h2 className="font-semibold text-3xl sm:text-4xl xl:text-5xl 2xl:text-6xl mt-6  leading-[1.13] tracking-tight">
            Special offer <br />
            in products
          </h2>
          <span className="block mt-6 text-slate-500 dark:text-slate-400">
            Perfect as a gift to a loved one or to yourself.
          </span>
          <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
            <ButtonPrimary
              className=" hover:text-white hover:bg-custom-pink"
            >
              Show All  
            </ButtonPrimary>
          </div>
        </div>

        <NcImage
          containerClassName="relative block lg:absolute lg:left-0 lg:bottom-0 mt-0 lg:mt-10 lg:mt-0 max-w-full md:max-w-[calc(55%-40px)] lg:max-w-[500px]"
          src={rightImgDemo}
        />
      </Link>
    </div>
  );
};

export default SectionPromo2;
