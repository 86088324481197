import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment,  } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ReviewItem from "./ReviewItem";


export interface ModalViewReviewProps {
  show: boolean;
  onCloseModalViewReview: () => void;
  data?:any;
}

const ModalViewReview: FC<ModalViewReviewProps> = ({
  show,
  onCloseModalViewReview,
  data = [],
}) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={onCloseModalViewReview}
      >
        <div className="flex items-center justify-center h-full text-center p-2">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 " />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 max-w-[500px] w-full max-h-full">
              <div
                className="flex-1 flex overflow-hidden max-h-full p-4 w-full text-left align-middle transition-all transform rounded-2xl bg-white 
               shadow-xl"
              >
                <span className="absolute right-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalViewReview} />
                </span>

                <div className="flex-1 overflow-y-auto hiddenScrollbar p-1">
                  <div className="text-[24px] text-custom-golden text-center font-medium mt-0 mb-8">Your review</div>
                  <ReviewItem data={data} />
                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalViewReview;
