import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import { Link } from "react-router-dom";
import { useCart } from '../context/CartContext';
import Textarea from "shared/Textarea/Textarea";

export interface ProductQuickViewProps {
  className?: string;
  data?: {
    id?: number;
    name?: string;
    price?: number;
    description?: string;
    faq?: string;
    link?: any;
    image?: string;
    images?: string[];
    isLiked?: boolean;
    favourite?: boolean;
    attribute_detail?: any[];
  };
}

const ProductQuickView: FC<ProductQuickViewProps> = ({ className = "", data = {} }) => {
  const { addToCart } = useCart();
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
  const images = (data?.images?.length ? data?.images : []);
  images?.shift();

  var newAccordionDetailArray: Array<any> = [];
  if (data.description && data.description != "<p><br></p>") {
    newAccordionDetailArray.push({ 'name': 'Description', 'content': data.description });
  }
  if (data.faq && data.faq != "<p><br></p>") {
    newAccordionDetailArray.push({ 'name': 'FAQ', 'content': data.faq });
  }

  const attribute_detail = (data?.attribute_detail?.length ? [data?.attribute_detail[0]] : []);

  const [variantActive, setVariantActive] = React.useState(0);
  const [size, setSize] = React.useState(((attribute_detail.length > 0) ? attribute_detail[0]['all_value'] : []));
  const [attributeSelected, setAttributeSelected] = React.useState(((attribute_detail.length > 0) ? ((attribute_detail[0]['all_value'].length > 0) ? attribute_detail[0]['all_value'][0] : {}) : {}));

  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);

  // const notifyAddTocart = () => {
  //   toast.custom(
  //     (t) => (
  //       <NotifyAddTocart
  //         productImage={LIST_IMAGES_DEMO[0]}
  //         qualitySelected={qualitySelected}
  //         show={t.visible}
  //         sizeSelected={sizeSelected}
  //         variantActive={variantActive}
  //       />
  //     ),
  //     { position: "top-right", id: "nc-product-notify", duration: 3000 }
  //   );
  // };

  const renderVariants = () => {
    if (!variants || !variants.length) {
      return null;
    }

    return (
      <div>
        <label htmlFor="">
          <span className="text-sm font-medium">
            Color:
            <span className="ml-1 font-semibold">
              {variants[variantActive].name}
            </span>
          </span>
        </label>
        <div className="flex mt-2.5">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative flex-1 max-w-[75px] h-10 rounded-full border-2 cursor-pointer ${variantActive === index
                ? "border-custom-golden "
                : "border-transparent"
                }`}
            >
              <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                <img
                  src={variant.thumbnail}
                  alt=""
                  className="absolute w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSizeList = () => {
    if (!allOfSizes || !sizes || !sizes.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
          </label>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="##"
            className="text-primary-6000 hover:text-primary-500"
          >
            See sizing chart
          </a>
        </div>
        <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-2.5">
          {allOfSizes.map((size, index) => {
            const isActive = size === sizeSelected;
            const sizeOutStock = !sizes.includes(size);
            return (
              <div
                key={index}
                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${sizeOutStock
                    ? "text-opacity-20  cursor-not-allowed"
                    : "cursor-pointer"
                  } ${isActive
                    ? "bg-custom-golden border-custom-golden text-white hover:bg-custom-golden"
                    : "border-slate-300  text-slate-900  hover:bg-neutral-50"
                  }`}
                onClick={() => {
                  if (sizeOutStock) {
                    return;
                  }
                  setSizeSelected(size);
                }}
              >
                {size}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white  nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 ";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl font-semibold hover:text-custom-pink transition-colors">
            <Link to={data.link}>{data.name}</Link>
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={((attributeSelected?.attribute_price) ? (attributeSelected?.attribute_price) : (data.price))}
            />

            {/*<div className="h-6 border-l border-slate-300 dark:border-slate-700"></div>

            <div className="flex items-center">
              <Link
                to={data.link}
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-custom-golden" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </Link>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{status}</span>
              </div>
            </div>*/}
          </div>


        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/*<div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div>*/}
        {/*<div className="">{renderSizeList()}</div>*/}

        {
          attribute_detail.length > 0 && (
            attribute_detail.map((item: any, index: any) => {
              return (<div key={index}>
                <div className="flex justify-between font-medium text-sm">
                  <label htmlFor="">
                    <span className="capitalize">
                      {item.name} :
                      <span className="ml-1 font-semibold uppercase">{attributeSelected.value}</span>
                    </span>
                  </label>
                </div>
                <div className="grid grid-flow-col auto-cols-max gap-2 mt-2.5">
                  {
                    item.all_value.map((size: any, index2: any) => {
                      const isActive = ((size.product_attribute_value_id === attributeSelected.product_attribute_value_id) && (size.product_attribute_id === attributeSelected.product_attribute_id));
                      const sizeOutStock = false;
                      return (
                        <div
                          key={index2}
                          className={`relative px-2 h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                      text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${sizeOutStock
                              ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                              : "cursor-pointer"
                            } ${isActive
                              ? "bg-custom-golden border-custom-golden text-white hover:bg-custom-golden"
                              : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                            }`}
                          onClick={() => {
                            if (sizeOutStock) {
                              return;
                            }
                            setAttributeSelected(size);
                          }}
                        >
                          {size.value}
                        </div>
                      );
                    })}
                </div>
              </div>)
            })
          )
        }

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        {/* <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
            />
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            onClick={() => addToCart({...data,selected_attribute:attributeSelected},qualitySelected)}//{notifyAddTocart}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to cart</span>
          </ButtonPrimary>
        </div> */}

        <div className="flex">
          {/* <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
              max={10}
            />
          </div> */}
          {/* <ButtonPrimary
            className="flex-1 flex-shrink-0"
            onClick={() => addToCart({...product,selected_attribute:attributeSelected},qualitySelected)} //{notifyAddTocart} //old function
            
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to cart</span>
          </ButtonPrimary> */}
          {/* Button */}
          {/* Button */}
          <ButtonPrimary
            sizeClass="py-3 px-4 sm:py-3.5 sm:px-6"
            fontSize="text-md sm:text-md md:text-md lg:text-md xl:text-md"
            onClick={handleModalOpen}
          >
            Product inquiry
          </ButtonPrimary>




        </div>

        {/*  */}
        <hr className=" border-slate-200 "></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo
          data={newAccordionDetailArray}
        />
      </div>
    );
  };


  const [showModal, setShowModal] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
    setTimeout(() => setIsAnimating(true), 50); // Start animation after modal appears
  };

  const handleModalClose = () => {
    setIsAnimating(false); // Animate out
    setTimeout(() => setShowModal(false), 300); // Close modal after animation
  };

  return (
    <>
      <div className={`nc-ProductQuickView ${className}`}>
        {/* MAIn */}
        <div className="lg:flex">
          {/* CONTENT */}
          <div className="w-full lg:w-[50%] ">
            {/* HEADING */}
            <div className="relative">
              <div className="aspect-w-16 aspect-h-16">
                <img
                  src={data.image || ''}
                  className="w-full rounded-xl object-cover bg-[white]"
                  alt="product detail 1"
                />
              </div>

              {/* STATUS */}
              {/*{renderStatus()}*/}
              {/* META FAVORITES */}
              <LikeButton liked={data.favourite || false} className="absolute right-3 top-3 " productId={data.id} />
            </div>
            {/*<div className="hidden lg:grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-5 xl:mt-5">
            {images.map((item : any, index) => {
              return (
                <div key={index}>
                  <img
                    src={item}
                    className="w-full rounded-xl object-cover"
                    alt="product detail 1"
                  />
                </div>
              );
            })}
          </div>*/}
          </div>

          {/* SIDEBAR */}
          <div className="w-full lg:w-[50%] pt-6 lg:pt-0 lg:pl-7 xl:pl-8">
            {renderSectionContent()}
          </div>
        </div>
      </div>
      {/* Modal */}
      {showModal && (
        <div
          className={`fixed inset-0 ml-0 z-50 flex items-center justify-center bg-black/40  opacity-100 transition-opacity duration-300 ease-out ${isAnimating ? "opacity-100" : "opacity-0"
            }`}
          onClick={handleModalClose} // Close modal on backdrop click
        >
          <div
            className={`relative bg-white rounded-lg shadow-lg w-full max-w-lg p-6 mx-2 md:mx-4 transition-transform duration-500 ease-out ${isAnimating ? "translate-y-0" : "translate-y-full"
              }`}
            onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing it
          >
            <h2 className="text-2xl font-semibold text-custom-pink  text-center mb-4">
              Product Inquiry
            </h2>

            {/* Form */}
            <form className="space-y-4">
              <label className="block">
                <span className="text-neutral-800 ">
                  First name
                </span>
                <input
                  type="text"
                  className="block w-full border-neutral-200 focus:border-[#8672c7] focus:ring focus:ring-[#8672c747] focus:ring-opacity-50 bg-white disabled:bg-neutral-200 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                  placeholder="Enter your name"
                />
              </label>

              <label className="block">
                <span className="text-neutral-800 ">
                  Phone Number
                </span>
                <input
                  type="tel"
                  className="block w-full border-neutral-200 focus:border-[#8672c7] focus:ring focus:ring-[#8672c747] focus:ring-opacity-50 bg-white disabled:bg-neutral-200  rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                  placeholder="Enter your phone number"
                />
              </label>

              <label className="block">
                <span className="text-neutral-800">
                  Email ID
                </span>
                <input
                  type="email"
                  className="block w-full border-neutral-200 focus:border-[#8672c7] focus:ring focus:ring-[#8672c747] focus:ring-opacity-50 bg-white  disabled:bg-neutral-200 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                  placeholder="Enter your email"
                />
              </label>

              <label className="block">
                <span className="text-neutral-800 ">
                  Description
                </span>
                <Textarea id="message" name="message" className="mt-1" rows={6} />
              </label>

              <ButtonPrimary type="submit" className="w-full">
                Submit
              </ButtonPrimary>
            </form>

            {/* Close Button with XIcon */}
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={handleModalClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 w-[30px] h-[30px] hover:bg-slate-100 rounded-full hover:rotate-180 transition-transform duration-200 p-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductQuickView;
