import React from 'react'
import { Helmet } from "react-helmet-async";
import 'tailwindcss/tailwind.css';

const renderPhoneIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
        </svg>
    );
};

const renderEmailIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
        </svg>
    );
};

const PageManufacturing = () => {

    return (
        <>
            <div
                className={`nc-PageManufacturing overflow-hidden`}
                data-nc-id="PageManufacturing"
            >
                <Helmet>
                    <title>Manufacturing Units || Toofani Masti</title>
                </Helmet>
                <div className="nc-PageManufacturing overflow-hidden">
                    <div className="pt-8 pb-8 lg:pb-12 xl:pb-12">
                        <div className="container lg:mt-12 lg:mb-0">
                        <h2 className="my-6 md:my-8 lg:my-10 xl:my-10 text-2xl sm:text-2xl lg:text-4xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-custom-pink dark:text-custom-pink text-left">
                            Manufacturing Units
                        </h2>
                            <div className="flex flex-col lg:flex-row gap-6 sm:gap-6 md:gap-6 lg:gap-6 xl:gap-6 justify-between items-start">
                                {/* Left side: Embedded Google Map */}
                                <div className="w-full lg:w-1/2 mb-0 lg:mb-8 xl:mb-8 lg:mb-0">
                                    <iframe
                                        src="https://www.google.com/maps/d/embed?mid=1yhIBFaFzPV6uK55_CZ9X66YiJRhtqD8&ehbc=2E312F&noprof=1"
                                        className="w-full h-64 sm:h-80 md:h-96 lg:h-[450px] xl:h-[450px]"
                                        allowFullScreen
                                        loading="lazy"
                                    ></iframe>
                                </div>

                                {/* Right side: Contact details */}
                                <div className="w-full lg:w-1/2 text-left">
                                    <div className="mb-4 md:mb-6 lg:md-8 xl:md-8">
                                        <h2 className="text-custom-pink font-medium text-xl mb-2">Akshar Snacks</h2>
                                        <p className="text-gray-700 mb-2 text-sm md:text-base ">281, Phase-2 G.I.D.C., Ambawadi, Wadhwan, Dist Surendranagar 363035 (Gujarat)</p>
                                        <a href="mailto:Aksharsnacks@gmail.com " className="inline-flex items-center text-sm lg:text-[16px]  text-gray-700 dark:text-gray-700  hover:text-custom-pink">{renderEmailIcon()} Aksharsnacks@gmail.com</a>
                                    </div>
                                    <div className="mb-4 md:mb-6 lg:md-8 xl:md-8">
                                        <h2 className="text-custom-pink font-medium text-xl mb-2">Akshar Food</h2>
                                        <p className="text-gray-700 mb-2 text-sm md:text-base"><strong>Unit 1</strong> : G1-106-107 Brij Industrial Area, Bharatpur-321001 (Rajasthan)</p>
                                        <p className="text-gray-700 mb-2 text-sm md:text-base"><strong>Unit 2</strong> : B11E Brij Industrial Area, Bharatpur-321001 (Rajasthan)</p>
                                        <a href="mailto:aksharfood02@gmail.com text-sm" className="inline-flex items-center lg:text-[16px] text-gray-700 dark:text-gray-700  hover:text-custom-pink">{renderEmailIcon()} aksharfood02@gmail.com</a>
                                    </div>
                                    <div className="mb-4 md:mb-6 lg:md-8 xl:md-8">
                                        <h2 className="text-custom-pink font-medium text-xl mb-2">Gujrat Namkeen LLP</h2>
                                        <p className="text-gray-700 mb-2 text-sm md:text-base">Old TVS Warehouse, Near Deedarganj Halt, Deedarganj-Gaurichak Road, Patna-800009 (Bihar)</p>
                                        <p><a href="mailto:gujaratnamkeen24@gmail.com" className="inline-flex items-center lg:text-[16px] text-gray-700 dark:text-gray-700  hover:text-custom-pink text-sm md:text-base">{renderEmailIcon()} gujaratnamkeen24@gmail.com</a></p>
                                        <p className='text-gray-700 mt-2 text-sm md:text-base flex'><span>{renderPhoneIcon()}</span> <a href="tel:+918410889999" className="inline-flex items-center lg:text-[16px]  text-gray-700 dark:text-gray-700  hover:text-custom-pink ">(+91)8410889999</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageManufacturing;