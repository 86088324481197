import React, { FC } from "react";
import { Helmet } from "react-helmet-async";


import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionHero from "./SectionHero";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import SectionPromo3 from "components/SectionPromo3";
// import rightImg from "images/hero-right1.png";

import visionmission from "images/visionmission.png";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Toofani Masti</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <section className="pt-8 pb-14 lg:pb-20 xl:pb-20 relative z-10">
        <div className="container">
          {/* <div className="lagle-heading">About us</div>
          <div className="legal-content">
            <p>Welcome to Toofani Masti, your premier destination for exquisite bath bombs and salts. Immerse yourself in luxury with our handcrafted products, proudly made in the heart of India and delivered to the UAE. Elevate your bathing experience with our meticulously crafted blends, designed to pamper and rejuvenate. Experience the essence of tradition and quality as we bring the artistry of Indian craftsmanship to your self-care routine. Indulge in the beauty of handmade excellence with Toofani Masti.</p>
          </div> */}

          <h2 className="my-6 md:my-8 lg:my-10 xl:my-10 text-2xl sm:text-2xl lg:text-4xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-custom-pink dark:text-custom-pink text-left">
            About Toofani Masti
          </h2>

          {/* <h1 className="text-3xl font-medium text-center text-custom-pink mb-6">
          About Toofani Masti
          </h1> */}


          <div className="bg-white p-6 sm:p-6 md:p-6 lg:p-8 xl:p-8 rounded-lg shadow-lg">
            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-lg xl:text-lg">
              We are dedicated to serving the rural heartland of India and bringing smiles to the faces of its people. In 2018, we registered our company as Akshar Snacks, which established the Toofani Masti brand.
            </p>
            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-lg xl:text-lg">
              Since then, we have grown and expanded our business with the support of our loyal customers in villages across the country. This success was made possible through our tireless efforts to build a multi-layered distribution network comprising distributors, wholesalers, and retailers, ensuring our products reach every corner that matters most for our survival.
            </p>

            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-lg xl:text-lg">
              Guided by the vision of our experienced team members, we now operate three manufacturing units in Gujarat, Rajasthan, and Bihar. Additionally, we are expanding our product range by introducing items that cater to ‘impulse buying
            </p>

            {/* <h2 className="text-xl font-medium text-custom-pink mb-2">Handcrafted Excellence</h2>
            <p className="mb-4 sm:mb-4 md:mb-4 lg:mb-6 xl:mb-6 text-sm md:text-base lg:text-lg xl:text-lg">
              Every item in our collection is a result of dedicated craftsmanship and attention to detail. From the selection of premium ingredients to the intricate blending processes, our bath bombs and salts are crafted with care to ensure the highest standards of quality. Our commitment to sustainability and ethical practices means you can indulge in our products with peace of mind, knowing they are made with respect for both people and the planet.
            </p> */}

          </div>

          {/* <div className="pt-10 md:pt-5 flex flex-col md:flex-row md:space-x-10 items-center relative">

                <div className="flex-grow">
                  <img className="w-full" src={visionmission} alt="" />
                </div>

                <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
                  <div className="legal-content">
                    <div className="main-sub-lable">Vision</div>
                    <p>To be a globally recognized leader, inspiring positive change and innovation across diverse industries. Toofani Masti General Trading envisions a future where our comprehensive range of services transforms lives, businesses, and experiences, leaving an indelible mark on the world.</p>

                    <div className="pt-3"></div>

                    <div className="main-sub-lable">Mission</div>
                    <p>Toofani Masti General Trading is dedicated to delivering unparalleled excellence across Spice Trading, Organic Foods Handmade Bath and Body products, IT services, Investment Solutions, E-commerce, Handmade Bath and Body products and VR solutions. Our mission is to empower individuals and businesses through strategic, innovative, and sustainable practices. We are committed to fostering growth, embracing diversity, and exceeding expectations to create lasting value for our clients and communities.</p>
                  </div>
                </div>

              </div> */}

        </div>
      </section>

      {/* <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />

        <SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic />

        <SectionPromo3 />
      </div> */}
    </div>
  );
};

export default PageAbout;
