import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from '../../contains/axios';
import API_HOST from '../../config/config';
import { useAuth  } from '../../contains/AuthContext';
import * as $ from 'jquery';
import 'jquery-validation';
import { Dialog, Transition } from "@headlessui/react";
import Prices from "components/Prices";
import { PRODUCTS } from "data/data";
import EmptyBox from "shared/EmptyBox/EmptyBox";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import ModalConfirmation from "components/ModalConfirmation";
import ModalAddReview from "components/ModalAddReview";
import ModalViewOrder from "components/ModalViewOrder";
import { StarIcon } from "@heroicons/react/24/solid";
import ModalViewReview from "components/ModalViewReview";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Button from "shared/Button/Button";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import moment from 'moment';

interface JQuery {
  $: any; // Replace with your types
}

const AccountOrder = () => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();


  const [showModalConfirmation, setShowModalConfirmation] = React.useState(false);
  const [showModalAddReview, setShowModalAddReview] = React.useState(false);
  const [showModalViewOrder, setShowModalViewOrder] = React.useState(false);
  const [showModalViewReview, setShowModalViewReview] = React.useState(false);
  const [dataModalViewReview, setDataModalViewReview] = React.useState([]);

  const [errMsg, setErrMsg] = useState<any>([]);
  const [successMsg, setSuccessMsg] = useState<any>([]);

  const reviewMessageRef = useRef<HTMLTextAreaElement>(null);
  const [reviewMessage, setReviewMessage] = useState<any>('');
  const [validReviewMessage, setValidReviewMessage] = useState<any>(true);
  const [viewReviewDetail, setViewReviewDetail] = useState<any[]>([]);

  const [productData, setProductData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [pleaseWaitBtn, setPleaseWaitBtn] = useState(false);
  const [viewSearchDataNotFound, setviewSearchDataNotFound] = useState(true);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [totalRecode, setTotalRecode] = useState(0);
  const [productDetail, setProductDetail] = useState<any[]>([]);
  const [deleteOrderDetail, setDeleteOrderDetail] = useState<any[]>([]);

  useEffect(() => {
    if (user) {
      if (user?.token && user?.role && user?.role=='user') {
      }
      else
      {
        navigate('/login');
      }
    }
    else
    {
      navigate('/login');
    }
  }, [user, navigate]);

  const [rating, setRating] = useState(0);

  useEffect(() => {},[rating]);

  const handleStarClick = (newRating: number) => {
    setRating(newRating);
  };

  const handleStarHover = (hoveredRating: number) => {
    // setRating(hoveredRating);
  };

  const handleStarLeave = () => {
    setRating(0);
  };

  const getStarClassName = (starValue: number) => {
    return starValue <= rating ? 'text-custom-golden' : '';
  };

  useEffect(() => {},[productDetail]);  

  

  const resetStateData = () => {    
    setProductData([]);
    setIsLoading(false);
    setLoadMoreBtn(false);
    setTotalRecode(0);
    setDataNotFound(false);
  };

  useEffect(() => {   
    resetStateData();  
    setPage(1);
  }, []);

  useEffect(() => {
    if(page == 1)
    {
      setviewSearchDataNotFound(true);
      getApiCall();
    }
  },[page]);

  useEffect(() => {},[productData]);

  useEffect(() => {
    if(Object.keys(successMsg).length > 0)
    {
      setTimeout(function () {
        setSuccessMsg([]);
      }, 10500);
    }
  }, [successMsg]);

  useEffect(() => {
    if(Object.keys(errMsg).length > 0)
    {
      setTimeout(function () {
        setErrMsg([]);
      }, 10500);
    }
  }, [errMsg]);

  const getApiCall = async () => {
    fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);
    if(page == 1){
      setPleaseWaitBtn(false);
    }
    else
    {
      setPleaseWaitBtn(true);
    }
    setLoadMoreBtn(false);
    try {            
      axios.post(API_HOST+'/api/order',{
        nopaginate : '0',
        page : `${page}`,
        per_page : '4',        
        user_id:user?user.id:'0'
      })
      .then(response => {
        if(response.data.success == 0)
        {
          if(typeof response.data.data.data !== 'undefined' && response.data.data.data !== null && Object.keys(response.data.data.data).length > 0)
          {
            setviewSearchDataNotFound(false);
            setProductData(prevProductData => [...prevProductData, ...response.data.data.data]);
            setPage(prevPage => (prevPage + 1));
            setTotalRecode(response.data.data.total);
            if(response.data.data.total == response.data.data.to)
            {
              setLoadMoreBtn(false);
            }
            else
            {
              setLoadMoreBtn(true);
            }
            setPleaseWaitBtn(false);
          }
          else{
            if(page == 1)
            {
              setviewSearchDataNotFound(false);
              setDataNotFound(true);
              setProductData([]);
            }
            setPage(prevPage => (prevPage + 1));
            setLoadMoreBtn(false);
            setPleaseWaitBtn(false);
          }
        }
      })
      .catch(error => {
        setPleaseWaitBtn(false);
      });         
    } catch (error) {
      setPleaseWaitBtn(false);
    } finally {
      setIsLoading(false);
    }
  };

  const renderProductItem = (item: any, index: number) => {
    const { image, price, quantity, product, category_name } = item;
    return (
      <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={product['image' as any]}
            alt={product['name' as any]}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium line-clamp-1">{product['name' as any]}</h3>
                <p className="mt-1 text-sm text-slate-500">
                  <span>{product['category_name' as any]}</span>
                  {/*<span className={`mx-2 border-l border-slate-200 dark:border-slate-700 h-4 ${product['size' as any] =='' ? 'hidden' : ''}`}></span>
                  <span>{product['size' as any]}</span>*/}
                </p>
                {
                  (item?.name || item?.value) && (
                    <p className="mt-1 text-sm text-slate-500 ">
                      <span className="capitalize">{item?.['name' as any]}:<span className="uppercase">{item?.['value' as any]}</span></span>
                    </p>
                  )
                }
              </div>
              <Prices className="mt-0.5 ml-2" price={price} />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 flex items-center">
              <span className="hidden sm:inline-block">Qty</span>
              <span className="inline-block sm:hidden">x</span>
              <span className="ml-2">{quantity}</span>
            </p>

            <div className="flex">
              <button
                onClick={() => { setRating(0); setReviewMessage(''); setValidReviewMessage(true); setDataModalViewReview(item); setShowModalAddReview(true);}}
                type="button"
                className={`py-1 px-2 font-medium text-indigo-600  ${(item?.product_review && item?.product_review != null) ? 'hidden' : ''}`}
              >
                Leave review
              </button>

              <button
                onClick={() => { setViewReviewDetail(item); setShowModalViewReview(true)}}
                type="button"
                className={`flex justify-center items-center py-1 px-2 font-medium text-indigo-600  ${(item?.product_review && item?.product_review != null) ? '' : 'hidden'}`}
              >
                {(item?.product_review?.rate > 0) && ( <>{item?.product_review?.rate} <StarIcon className="w-4 h-4 mx-1"/></>)} View review
              </button>
              
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleDeleteOrderDetail = async () => {
    if(deleteOrderDetail['id' as any] > 0)
    {      
      try {
          setErrMsg([]);
          const response = await axios.post(API_HOST+'/api/order_delete',{        
            id : deleteOrderDetail['id' as any],
            user_id:user?user.id:'0',
          })
          .then(response => {
              if(response.data.success == 0 || response.data.success == '0')
              {            
                setDeleteOrderDetail([]); setShowModalConfirmation(false); 
                var successArray: Array<any> = [];
                successArray["success" as any] = response.data.message;
                setSuccessMsg(successArray);
                resetStateData();  
                setPage(1);
              }                
          })
          .catch(error => {
              var newArray: Array<any> = [];
              if(!error?.response)
              {
                newArray['server_error' as any] = "No Server Response";
              }
              else if(error.response.data.success == 2)
              {
                newArray['server_error' as any] = (error.response.data.message);                 
              }
              else if(error.response?.status === 409 )
              {
                newArray['server_error' as any] = "Order not delete";
              }
              else{
                newArray['server_error' as any] = "Order not delete";
              }   
              setErrMsg(newArray);
          });            
      } catch (err) { 
        var newArray: Array<any> = [];              
        newArray["server_error" as any] = "Order not delete";
        setErrMsg(newArray);
        console.error('Error fetching data catch:', err);
      }
    }
    else
    {
      var newArray: Array<any> = [];              
      newArray["server_error" as any] = "Order not delete";
      setErrMsg(newArray);
    }
  }

  const renderOrder = () => {
    return (
      typeof productData !== 'undefined' && productData !== null && ( productData.length > 0 && (productData.map((item, index) => {
          return <div key={index} className="border border-slate-200  rounded-lg overflow-hidden z-0">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 ">
              <div>
                <p className="text-lg font-semibold">#{item["order_number" as any]}</p>
                <p className="text-slate-500  text-sm mt-1.5 sm:mt-2">
                  <span>{moment(item["created_at" as any]).format('MMM D, YYYY')}</span>
                  <span className="mx-2">·</span>
                  <span className="text-primary-500 capitalize">{((item["status" as any] == 'new') ? 'Pending' : item["status" as any])}</span>
                </p>
              </div>
              <div className="mt-3 sm:mt-0 flex justify-between sm:justify-end">
                <ButtonSecondary
                  sizeClass="py-3 px-4"
                  fontSize="text-sm font-medium"
                  onClick={() => { setProductDetail(item); setShowModalViewOrder(true); }}
                >
                  View Order
                </ButtonSecondary>

                {((item["status" as any] == 'new') && (
                  <ButtonSecondary
                  className="ml-2 transition  focus:ring-[#fa826a]"
                  sizeClass="py-3 px-4"
                  fontSize="text-sm font-medium"
                  onClick={() => { setDeleteOrderDetail(item); setShowModalConfirmation(true); }}
                >
                  Cancel Order
                </ButtonSecondary>
                ))}                
              </div>
            </div>
            <div className="border-t border-slate-200 p-2 sm:p-8 divide-y divide-y-slate-200 ">
              {item["cart_info" as any].map(renderProductItem)}
            </div>
          </div>
      })))
    );
  };

  function sendVideoResponseReviewSubmitCheck () {
    var checkVal: Array<any> = [];
    if(reviewMessage != ""){        
        setValidReviewMessage(true); 
        checkVal = [...checkVal,true];           
    }else{ 
        setValidReviewMessage(false); 
        checkVal = [...checkVal,false];
    }
    if(checkVal.includes(false)) { return false; } else { return true; }
  }

  useEffect(() => {},[dataModalViewReview]);

  const handleSendReviewSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();    
    if(!sendVideoResponseReviewSubmitCheck())
    { 
      return;
    }
    else{
      setValidReviewMessage(true);                    
    }

    setErrMsg([]);
    setSuccessMsg([]);

    const formData = new FormData();
    formData.append('user_id',`${user?.id}`);
    formData.append('product_id',`${dataModalViewReview?.['product_id' as any]}`);
    formData.append('order_id',`${dataModalViewReview?.['order_id' as any]}`);
    formData.append('rate',`${rating}`);
    formData.append('review',`${reviewMessage}`);
    
    try {
      axios.post(API_HOST+'/api/add_review',formData)
      .then(response => {
        if(response.data.success === 0)
        {
          setRating(0);
          setReviewMessage('');
          setValidReviewMessage(true);
          setDataModalViewReview([]);
          setShowModalAddReview(false);
          var successArray: Array<any> = [];
          successArray["success" as any] = response.data.message;
          setSuccessMsg(successArray);    
          resetStateData();  
          setPage(1);            
        }
      })
      .catch(error => {
        var newArray: Array<any> = [];              
        if(!error?.response)
        {
          newArray["server_error" as any] = "No Server Response";
        }
        else if(error.response.data.success == 2)
        {
          if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
          {                  
            Object.keys(error.response.data.data).forEach((item:any, index:any) => {
              newArray[item] = error.response.data.data[item].toString();
            });                  
          }
          else
          {
            newArray['server_error' as any] = error.response.data.message;
          }
        }
        else if(error.response?.status === 409 )
        {
          newArray["server_error" as any] = "review not added";
        }
        else{
          newArray['server_error' as any] = "review not added";
        }
        setErrMsg(newArray);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }  
  }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
          {
            (Object.keys(errMsg).length > 0) && (
              <div role="alert">
                <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  Error
                </div>
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                  {
                    Object.keys(errMsg).map((item:any,index:any) => (
                      <p key={index}>{errMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
          {
            (Object.keys(successMsg).length > 0) && (
              <div role="alert">
                <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                  Success
                </div>
                <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                  {
                    Object.keys(successMsg).map((item:any,index:any) => (
                      <p key={index}>{successMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
          {renderOrder()}      
          <EmptyBox show={dataNotFound ? '' : 'hidden' } />
          {/* PAGINATION */}            
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <ButtonPrimary className={`${pleaseWaitBtn ? '' : 'hidden'}`} loading >Loading...</ButtonPrimary>
            <ButtonPrimary className={`${loadMoreBtn ? '' : 'hidden'}`} onClick={() => fetchData()} disabled={isLoading} >Show me more</ButtonPrimary>            
          </div>
        </div>
      </CommonLayout>

      {/*<ModalConfirmation
        show={showModalConfirmation}
        onCloseModalConfirmation={() => setShowModalConfirmation(false)}
      />*/}

      <Transition appear show={showModalConfirmation} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={() => { setDeleteOrderDetail([]); setShowModalConfirmation(false); }}
        >
          <div className="flex items-center justify-center h-full text-center px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-flex xl:py-8 max-w-[500px] max-h-full">
                <div
                  className="flex-1 flex overflow-hidden max-h-full p-4 w-full text-left align-middle transition-all transform rounded-2xl bg-white 
                shadow-xl"
                >
                  <span className="absolute right-3 top-3 z-50">
                    <ButtonClose onClick={() => { setDeleteOrderDetail([]); setShowModalConfirmation(false); }} />
                  </span>

                  <div className="flex-1 overflow-y-auto hiddenScrollbar">
                      <div className="text-[24px] text-custom-pink text-center font-medium mb-3">Are You Sure?</div>
                      <div className="text-[16px] text-[#f2f1f3] text-center font-normal mb-3">Do you want cancel this order?</div>

                      <div className="my-6 flex justify-center items-center">
                        <Button 
                          className="transition ease-in-out mx-1 rounded-full bg-[#0b0f18] hover:bg-[#28a745] active:scale-95"
                          sizeClass="py-2.5 px-5"
                          fontSize="text-sm font-medium"
                          onClick={handleDeleteOrderDetail}
                        >Yes Cancel it!</Button>

                        <Button 
                          className="transition ease-in-out mx-1 rounded-full bg-[#0b0f18] hover:bg-[#c70000] active:scale-95"
                          sizeClass="py-2.5 px-5"
                          fontSize="text-sm font-medium"
                          onClick={() => { setDeleteOrderDetail([]); setShowModalConfirmation(false);}}
                        >No</Button>
                      </div>

                      <div className="bg-[#00000057] rounded p-3">
                          <div className="text-[#f2f1f3] text-xs font-normal">Please note:</div>
                          <div className="text-[#bfbac4] text-xs mt-2 leading-normal">The Cancellation request will be taken & blocked amount will automatically be added to your balance in 3-4 working days after verification by concerned team.</div>
                      </div>
                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      
      <Transition appear show={showModalAddReview} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={() => { setRating(0); setReviewMessage(''); setValidReviewMessage(true); setDataModalViewReview([]); setShowModalAddReview(false);}}
        >
          <div className="flex items-center justify-center h-full text-center p-2">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-flex xl:py-8 max-w-[500px] w-full max-h-full">
                <div
                  className="flex-1 flex overflow-hidden max-h-full p-4 w-full text-left align-middle transition-all transform rounded-2xl bg-white 
                shadow-xl"
                >
                  <span className="absolute right-3 top-3 z-50">
                    <ButtonClose onClick={() => { setRating(0); setReviewMessage(''); setValidReviewMessage(true); setDataModalViewReview([]); setShowModalAddReview(false);}} />
                  </span>

                  <div className="flex-1 overflow-y-auto hiddenScrollbar p-1">
                      <div className="text-[24px] text-custom-golden text-center font-medium mb-3">Add your review</div>        
                      <form onSubmit={handleSendReviewSubmit} encType="multipart/form-data" className="mt-5 grid grid-cols-1 gap-5">

                        <label className="block text-center">
                          <span className="block text-sm text-neutral-800  mb-2">
                            Add Your Rating
                          </span>
                          <div className="flex justify-center" >
                            {[...Array(5)].map((_, index) => {
                              const starValue = index + 1;
                              return (
                                <span
                                  key={index}
                                  className={getStarClassName(starValue)}
                                  onClick={() => handleStarClick(starValue)}
                                  onMouseEnter={() => handleStarHover(starValue)}
                                >
                                  <StarIcon className="cursor-pointer w-5 h-5" />
                                </span>
                              );
                            })}
                          </div>
                        </label>

                        <label className="block">
                          <span className="text-sm text-neutral-800">
                            Full Name
                          </span>
                          <Input id="review_name" name="review_name"
                            defaultValue={user?.name}
                            type="text"
                            placeholder="Enter your name"
                            className="mt-1 h-12"
                          />
                        </label>

                        <label className="block">
                          <span className="text-sm text-neutral-800">
                          Review
                          </span>
                          <Textarea id="review" name="review" ref={reviewMessageRef} onChange={(e) => { setValidReviewMessage(((e.target.value.length > 0) ? true : false)); setReviewMessage(e.target.value)}} className="mt-1.5" placeholder="Review text here..." />
                          <label className={`error block text-right p-1 text-[14px] text-[#fa826a] ${!validReviewMessage ? "" : "hidden"} `} htmlFor="review">Please enter valid description</label>
                        </label>

                        <ButtonPrimary type="submit">Submit</ButtonPrimary>
                      </form>                        
                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      

      {/*<ModalAddReview
        initialRating={0}
        show={showModalAddReview}
        onCloseModalAddReview={() => setShowModalAddReview(false)}
      />*/}

      <ModalViewOrder 
        show={showModalViewOrder}
        data={productDetail}
        onCloseModalViewOrder={() => setShowModalViewOrder(false)}
      />
      <ModalViewReview 
        data={viewReviewDetail}
        show={showModalViewReview}
        onCloseModalViewReview={() => {setShowModalViewReview(false); setViewReviewDetail([]);}}
      />
    </div>
  );
};

export default AccountOrder;
